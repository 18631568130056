import React from 'react'
import { Helmet } from "react-helmet";
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';

export default function Setting(props) {
  return (
    <>
      <Navbar setProgress={props.setProgress} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeChamp | Setting</title>
        <link rel="canonical" href="https://www.typechamp.com/setting" />
      </Helmet>

      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div style={{textAlign:'center'}}>
          We are working on it...
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
      <Footer/>

    </>
  )
}
