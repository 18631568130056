import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import img from '../asset/home.jpg'
import Wave from 'react-wavify'

import '../component/css/home.css'

import Odometer from 'react-odometerjs'
import 'odometer/themes/odometer-theme-default.css';
import { useEffect } from 'react';


export default function Home(props) {

  const host = process.env.REACT_APP_API_REQUEST;
  // const host = "http://localhost:5000";

  const [data, setData] = useState([])

  const [user, setUser] = useState([])
  const [typeD, setTypeD] = useState([])
  const [topType, setTopType] = useState([])

  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);

  window.addEventListener("scroll", () => {

    if (window.pageYOffset > 900) {
      setValue(user?.length);
      setValue1(typeD?.length);
      setValue2(topType[0]?.wpm);

    }
  })



  const fetchData = async () => {
    const uresponse = await fetch(`${host}/record/toprecord`, {
      method: 'GET',

    });
    const data = await uresponse.json();

    if (data) {
      setData(data);
    }
  }

  const fetchUser = async () => {
    const uresponse = await fetch(`${host}/auth/getun`, {
      method: 'GET',

    });
    const data = await uresponse.json();

    if (data) {
      setUser(data);
    }
  }

  const fetchAllType = async () => {
    const uresponse = await fetch(`${host}/record/typeall`, {
      method: 'GET',

    });
    const data = await uresponse.json();

    if (data) {
      setTypeD(data);
    }
  }
  const fetchOneType = async () => {
    const uresponse = await fetch(`${host}/record/topone`, {
      method: 'GET',

    });
    const data = await uresponse.json();
    console.log(data);
    if (data) {

      setTopType(data);
    }
  }




  useEffect(() => {
    fetchUser();
    fetchData();
    fetchAllType();
    fetchOneType();
  }, [])






  return (
    <>
      <Navbar setProgress={props.setProgress} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeChamp</title>
        <link rel="canonical" href="https://www.typechamp.com/" />
      </Helmet>

      {/* <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <svg width={1300} id="eTX5i2mFegf1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 430 152" shapeRendering="geometricPrecision" text-rendering="geometricPrecision">
            <path className='home_top_star' d="M15.16035,14.18211h285.06044L150.83588,163.56702h-136.621l.94547-149.38491Z" transform="translate(-12.351447-13.499231)" />
            <path className='home_top_star' d="M15.160364,14.18211h285.064516L150.837834,163.56702h-136.622954l.945484-149.38491Z" transform="matrix(-1 0 0-1 443.130023 164.319796)" />
          </svg>
        </div>
        <div className='home_top_cont_header'>
          <div style={{ width: '600px' }}>
            <h1>Hello,</h1>
            <div>

              <h1>Welcome to TypeChamp.com</h1>
              <div>Provide timed typing tests to assess users' </div>
              <div>current typing speed and accuracy </div>
              <Link to="/Type">
                <button className='home_top_btn'>  Start Type </button>
              </Link>
            </div>
          </div>
          <div style={{ height: '285px' }}>
            <img className='homt_top_header_img' src="https://cdn.pixabay.com/photo/2024/02/22/11/02/woman-8589721_640.png" alt="" />
          </div>
        </div>
        <br />

      </div> */}

      <div>

        <div className="sethomeback">
          <div className="wrapper">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className='settopintro'>
          <div className='set_a_b_name'>
            <div>
              <p >Welcome To TypeChamp.com</p>
            </div>

            <div className='edcation_line_pervious' style={{marginTop:'-25px'}}>
              <h1 className='education_line'>
                <span className='changewordstyle'> Transform </span>
                your typing, unlock speed and
                <span className='changewordstyle'> Accuracy </span>
                with our
                <span className='changewordstyle'> interactive </span>
                platform!
              </h1>
            </div>

           


            <div style={{marginTop:'-25px'}}>
              <div className='setanipara'>
                <p>Sharpen Your Skills, One Key at a Time!</p>
              </div>
              <div>
                <Link to="/type"> <button className='mybtn '>Start Typing</button></Link>

              </div>

            </div>

          </div>
          <div className='marginwave'>
            <div>
              <Wave fill='#39edff'
                paused={false}
                options={{
                  height: 50,
                  amplitude: 40,
                  speed: 0.20,
                  points: 3
                }}
              />
            </div>
          </div>
        </div>





      </div>

      {/* <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div> */}





      <div style={{ background: '#222222' }}>





        <section data-aos="fade-down" style={{ display: 'flex', justifyContent: 'center', margin: '100px 20px' }}>

          <div className='game_box'>
            <div >
              <Link to="/gameone">
                <img width={375} src="https://cdn1.typingtyping.com/wp-content/uploads/2019/11/z-type-game-min.png" alt="" />
              </Link>
            </div>
            <div>
              <h3>Z-Type</h3>
              <div className='notify_coming'>Coming soon</div>
              <div style={{ display: "flex", justifyContent: 'space-between' }}>


                <div className='tagGreen'>
                  <span className="material-symbols-outlined">
                    play_circle
                  </span>
                  <div>
                    Play
                  </div>
                </div>

                <div className='tagGold'>
                  <span className="material-symbols-outlined">
                    leaderboard
                  </span>
                  <div>
                    Leaderboard
                  </div>
                </div>


                <div className='tagBlue'>
                  <span className="material-symbols-outlined">
                    info
                  </span>
                  <div>
                    About
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='game_box'>
            <div >
              <Link to="/gameone">

                <img width={375} style={{ height: '275px' }} src="https://www.typinggames.zone/web/game-thumbnails/typingattack-small.png" alt="" />
              </Link>
            </div>
            <div>
              <h3>Typing Attack</h3>
              <div className='notify_coming'>Coming soon</div>
              <div style={{ display: "flex", justifyContent: 'space-between' }}>


                <div className='tagGreen'>
                  <span className="material-symbols-outlined">
                    play_circle
                  </span>
                  <div>
                    Play
                  </div>
                </div>

                <div className='tagGold'>
                  <span className="material-symbols-outlined">
                    leaderboard
                  </span>
                  <div>
                    Leaderboard
                  </div>
                </div>


                <div className='tagBlue'>
                  <span className="material-symbols-outlined">
                    info
                  </span>
                  <div>
                    About
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='game_box' >
            {/* <div className='game_box' style={{ filter: 'blur(2px)' }}> */}
            <div >
              <Link to="/gameone">
                <img width={375} height={275} src="https://www.kidztype.com/thumbs/space-typing-junior.webp" alt="" />
              </Link>
            </div>
            <div>
              <h3>Space Typing</h3>
              <div className='notify_coming'>Coming soon</div>
              <div style={{ display: "flex", justifyContent: 'space-between' }}>


                <div className='tagGreen'>
                  <span className="material-symbols-outlined">
                    play_circle
                  </span>
                  <div>
                    Play
                  </div>
                </div>

                <div className='tagGold'>
                  <span className="material-symbols-outlined">
                    leaderboard
                  </span>
                  <div>
                    Leaderboard
                  </div>
                </div>


                <div className='tagBlue'>
                  <span className="material-symbols-outlined">
                    info
                  </span>
                  <div>
                    About
                  </div>
                </div>
              </div>
            </div>
          </div>


        </section>




        <section>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '70px 20px' }}>
            <div style={{ width: '600px' }}>
              <h2>How do we operate and develop?</h2>

              <p style={{ width: '550px' }}>This is a MERN stack project that site helps to improve your typing skills from various types of games, A site will provide you with the best experience and best performance, and we try our best to deliver you our best product and services.</p>

              <p style={{ width: '550px' }}>If you have any changes for us don't hesitate to contact us in the given links, we are happy to see your feedback and we will put it into the site. </p>

              <p style={{ width: '550px' }}>we are now in the development stage and we improve on a daily basis and improve the performance. if you have to support us please contact us with your opinion and we love it.</p>

              <div style={{ display: 'flex', justifyContent: 'space-between', width: '550px', marginTop: '35px' }}>

                <div className='about_details'>
                  <div>Member</div>
                  <div><Odometer format="d" duration={1000} value={value} /></div>
                </div>
                <div className='about_details'>
                  <div>T.T.D</div>
                  <div><Odometer format="d" duration={1000} value={value1} /></div>
                </div>
                <div className='about_details'>
                  <div>Top Wpm</div>
                  <div><Odometer format="d" duration={1000} value={value2} /></div>
                </div>

              </div>
            </div>
            <div >
              <img src={img} width={600} alt="" />
            </div>
          </div>
        </section>


        <section className='home_botton_cont' data-aos="fade-up" >
          <div className='home_banner_card' style={{ background: '#fd00ff' }}>

            <div >
              <h2>Try Our best game and Impover your Type Skills</h2>

              <p>We have a related type we develop using logic to base language as javascript, this game much time to develop and design if you like our games comment to us by typing "Nice Game".</p>
              <Link to="/game">
                <button>Try Game</button>
              </Link>
            </div>
          </div>
        </section>



        <div className='leader_board_head' >
          <h2 >Leaderboard</h2>
          <div></div>
        </div>


        <section style={{ display: 'flex', justifyContent: 'center' }}>
          <table className='table_list_set'>
            <tr>
              <th className='table_list_th'>Rank</th>
              <th className='table_list_th'>Name</th>
              <th className='table_list_th'>WPA</th>
              <th className='table_list_th'>Time</th>
              <th className='table_list_th'>Accuracy</th>
            </tr>

            {data.map((element, index) => {
              if (index <= 7) {

                return <tr>
                  <td><span style={{color:'aqua'}}>#</span>{index + 1}</td>
                  <td>{element.name ? element.name : '-'}</td>
                  <td>{element?.wpm}</td>
                  <td>{element?.time}s</td>
                  <td>{element?.accuracy}</td>
                </tr>

              }
            })}





          </table>
        </section>





        <section className='home_botton_cont' data-aos="fade-up" >
          <div className='home_banner_card'>
            <div>
              <h2>Top Typing Score Of today on our site Updated</h2>

              <p>We have a daily leaderboard where we show all types of player playing their scores and according to the game we have different sections or accounting to time also and more respectively.</p>
              <Link to='/competition'>

                <button>Leaderboard</button>
              </Link>
            </div>
          </div>
        </section>




        <Footer />
      </div>

    </>
  )
}
