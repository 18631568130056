import React from 'react'
import '../component/css/footer.css'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
     
      <section className='footer_sec'>
        <div className='footer_inner'>
          <div>
            <div>
              <h3>TypeChamp</h3>
            </div>
            <div className='footer_links' >
              <div >
                <Link to="/feedback">Feedback</Link><br />
                <a href="">Our Store</a><br />
                
              </div>
              <div>
                <a href="">Support</a><br />
                <a href="">Help</a><br />
              
              </div>
              <div>
                <a href="">Alumni</a><br />
                <a href="">News</a><br />
              </div>
              <div>
                <a href="">Social</a><br />
                <a href="">Networks</a><br />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '20px' }}>
            <div className="social-links">
									<a href="https://www.linkedin.com/in/imaman-gupta/" target='_blank'><i className="fab fa-linkedin-in"></i></a>
									<a href="https://www.instagram.com/_aman_live/" target='_blank'><i className="fab fa-instagram"></i></a>
									<a href="https://twitter.com/aman_d_gupta" target='_blank'><i className="fab fa-twitter"></i></a>
									{/* <a href="#"><i className="fab fa-facebook-f"></i></a> */}
									<a href="#"><i className="fab fa-youtube"></i></a>
								</div>
            </div>

          </div>

          <div style={{width:'50%'}}>
            <p style={{ fontSize: '15px' }}>Get Fresh new TypeChamp news</p>
            <div>
              <div className='newletter_btn'>
                <input type="text" />
                <button>Subscribe</button>
              </div>
            </div>
            <div>
              <input type="checkbox" />
              <label style={{fontSize:'13px'}} htmlFor="" >By checking the box, you are that you are at least 16 years of age.</label>
            </div>

          </div>

        </div>
        <div className='footer_copyright'>
          <div>
            <a style={{ paddingLeft: '0px' }} className='f_l_l' href="">Website Terms</a>
            <a className='f_l_l' href="">Private Policy</a>
            <a className='f_l_l' href="">Accessibily</a>
            <a className='f_l_l' href="">Site Map</a>
            <a className='f_l_l' href="">Suppiere code of conduct</a>
            <a className='f_l_l' href="">Website Terms</a>
            <a className='f_l_l' href="">Marketing</a>
            <a className='f_l_l' href="">Do not sell by Infomation</a>
            <a className='f_l_l' href="">Terms & Conditions</a>
          </div>
          <div style={{ marginTop: '20px' }}>
            <p style={{ fontSize: '12px' }}>&copy; 2023 TypeChamp, LLC. All Right Reserved.</p>
          </div>
        </div>
      </section>
    </>
  )
}
