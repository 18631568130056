import React, { useState, useEffect, useRef, StrictMode } from 'react';
import './css/type.css'
import { useDispatch, useSelector } from 'react-redux';
import { addCorrect, addWorng, addSec } from '../store/nowTypeSlice';
import ReverseCountdown from '../utils/ReverseCountdown';
import { useNavigate } from 'react-router-dom';
import { nowTrueTypeing } from '../store/nowTypeSlice';

export default function TypeControler() {
    const [currect, setCurrect] = useState(0);
    const [worng, setWorng] = useState(0);
    const [space, setSpace] = useState(0);
    const [wpm, setWpm] = useState(0);

    
    
   
    // const host = "http://localhost:5000";
    const host = process.env.REACT_APP_API_REQUEST;

    // const [nowSec, setNowSec] = useState(15);
    const nowType = useSelector((store) => store.nowType.typerun);
    const nowSec = useSelector((store) => store.nowType.second);
    const userFix = useSelector((store) => store.user.userCreate);
    const userids = useSelector((store) => store.user.userId);
    const username = useSelector((store) => store.user.name);

    const [setstart, setSetstart] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const savaRecord = async() => {
        var secDiv = 0.25;
        var whatDef = "Defult";
        if (nowSec=== 15) {
            console.log('second 15 here...');
            var secDiv = 0.25;
        }else if(nowSec === 30){
            console.log('second 30 here...');
            var secDiv = 0.50;
            
        }else if (nowSec === 60) {
            console.log('second 60 here...');
            var secDiv = 1;
        }else if(nowSec === 120){
            var secDiv = 2;
        }

        if (nowSec !== 60) {
            var whatDef = "Custom";
        }

        dispatch(nowTrueTypeing(false))

        const response = await fetch(`${host}/record/createrecord`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name:username, userId:userids, wpm: parseInt((currect / 5) / secDiv), accuracy:parseInt(currect / (currect + worng) * 100) ,time: nowSec ,wordC:currect,wordW:worng,wordT:currect + worng,setUp:whatDef,Lanugage:'English' })
        });

        const json = await response.json();
        if (json) {
            console.log(json.user._id);
            navigate(`/typespeed/${json.user._id}`);
        }
    }
    

    if (nowType) {
        if (userFix) {
            savaRecord()           
        }else{
            dispatch(addCorrect(currect));
            dispatch(addWorng(worng));
            navigate('/typer');
        }
    }


    const againStart = () => {
        showTypeData();
        setSetstart(true)
        dispatch(addCorrect(0));
        dispatch(addWorng(0));

    }

    document.onkeypress = (e) => {
        if (setstart) {
            startCount();
        }
        setSetstart(false)

        let w_active = document.getElementById('w_active');
        let l_active = document.getElementById('l_active');

        if (e.key === l_active?.innerText) {
            correctWord();


            if (l_active.nextElementSibling) {
                l_active.nextElementSibling.setAttribute('id', 'l_active');
                l_active.remove();
            } else {
                l_active.remove();
            }
        } else if (e.key === ' ') {
            if (w_active?.firstElementChild) {
                worngWord();
            }
            if (w_active?.nextElementSibling) {

                w_active.nextElementSibling.firstElementChild.setAttribute('id', 'l_active');
                w_active.nextElementSibling.setAttribute('id', 'w_active');
                w_active.nextElementSibling.setAttribute('class', 'w_active');
                w_active.remove();
            }
            if (space === 10) {
                addTenWords();
                setSpace(0);
            }else{

                setSpace(space + 1);
            }
        } else {

            worngWord();
        }
    }


    const startCount = () => {
        console.log("start here");
    }

    const changeSec = (sec) => {
        dispatch(addSec(sec));
    }



    const worngWord = () => {
        setWorng(worng + 1);
        dispatch(addWorng(worng));
        if (document.getElementById('redio_red')) {
            document.getElementById('redio_red').style.opacity = "1";
            document.getElementById('redio_green').style.opacity = "0.2";
        }
    }

    const correctWord = () => {
        setCurrect(currect + 1);
        dispatch(addCorrect(currect));

        if (document.getElementById('redio_green')) {
            document.getElementById('redio_green').style.opacity = "1";
            document.getElementById('redio_red').style.opacity = "0.2";
        }
    }

    useEffect(() => {
        showTypeData();
    }, []);


    const wordData = ['capital','now','year','not','catch','car','card','always','door',
    'jungle','among','amount','show','draw','center','the','side','lot',
    'love','make','yes','drive','give','drop','yet','low','move',
    'much','red','under','party','report','voice','nice','none','half',
    'sea','valid','state','score','it','also','very','alone','item',
    'similar','section','carry','case','major','lose','find','fine','mind',
    'then','as','space','speak','last','late','sound','fire','large',
    'ok','old','black','wait','blue','visa','blood','period','art',
    'pay','incise','child','check','change','chance','chair','zip','everyone',
    'deep','fish','floor','data','five','viral','view','big','only','once']



    const showTypeData = () => {
        
        let type_cont = document.getElementById('type_cont');

        type_cont.innerHTML = '<span class="ns_active"></span>';


        for (let index = 0; index < 55; index++) {
            let randWrod = Math.floor(Math.random() * wordData.length);

            // console.log(wordData[randWrod]);


            if (index === 0) {
                let nowword = wordData[randWrod]

                type_cont.innerHTML += '<div id="w_active" class="w_active"> </div>'
                let active = document.getElementById('w_active');

                for (let i = 0; i < nowword.length; i++) {
                    if (nowword[0] && !active.firstElementChild) {
                        active.innerHTML += '<litter id="l_active" >' + nowword[i] + '</litter>';
                    } else {
                        active.innerHTML += '<litter >' + nowword[i] + '</litter>';
                    }
                }
            } else {

                type_cont.innerHTML += ' <div id="nextone"> </div>';
                let nextone = document.getElementById('nextone');
                let nowword = wordData[randWrod]
                for (let i = 0; i < nowword.length; i++) {

                    if (nowword.length === nextone.childElementCount + 1) {
                        nextone.innerHTML += '<litter >' + nowword[i] + '</litter>';
                        nextone.removeAttribute('id');
                    } else {
                        nextone.innerHTML += '<litter >' + nowword[i] + '</litter>';
                    }
                }
            }
        }
    }

    const addTenWords = () => {
        let type_cont = document.getElementById('type_cont');

        for (let index = 0; index < 10; index++) {
            let randWrod = Math.floor(Math.random() * wordData.length);

            type_cont.innerHTML += ' <div id="nextone"> </div>';
            let nextone = document.getElementById('nextone');
            let nowword = wordData[randWrod]
            for (let i = 0; i < nowword.length; i++) {

                if (nowword.length === nextone.childElementCount + 1) {
                    nextone.innerHTML += '<litter >' + nowword[i] + '</litter>';
                    nextone.removeAttribute('id');
                } else {
                    nextone.innerHTML += '<litter >' + nowword[i] + '</litter>';
                }
            }

        }
    }



    return (
        <>

            <section id='record'>
                {setstart ? <>
                    <div className='tyc_controler'>
                        <div className='tyc_con'>
                            <div className='tyc_menu_sec'>
                                <span className={nowSec === 30?`tyc_menu sechighColor`:`tyc_menu`}>Default</span>
                                <span className={nowSec !== 30?`tyc_menu sechighColor`:`tyc_menu`}>Custom</span>
                                
                            </div>
                            <div className='tyc_divider'></div>
                            <div className='tyc_menu_sec'>
                                <span style={{ color: "#0cffff" }} className='tyc_menu'>Time</span>
                                <span className='tyc_menu' style={{color:'gray'}}>Words</span>
                            </div>
                            <div className='tyc_divider'></div>
                            <div className='tyc_menu_sec'>
                                <span onClick={() => changeSec(15)} className={nowSec === 15 ? 'tyc_menu sechighColor' : 'tyc_menu'} >15</span>
                                <span onClick={() => changeSec(30)} className={nowSec === 30 ? 'tyc_menu sechighColor' : 'tyc_menu'} >30</span>
                                <span onClick={() => changeSec(60)} className={nowSec === 60 ? 'tyc_menu sechighColor' : 'tyc_menu'} >60</span>
                                <span onClick={() => changeSec(120)} className={nowSec === 120 ? 'tyc_menu sechighColor' : 'tyc_menu'} >120</span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                </> : <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className='tyc_typer_cont' >
                        <div className='tyc_typer'>
                            <div className='radio_signal_blue'></div>
                            <div id='redio_green' className='radio_signal_green'></div>
                            <div id='redio_red' className='radio_signal_red'></div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className='tyc_typer_cont'>
                        <div className='tyc_typer'>
                            <ReverseCountdown seconds={nowSec} />
                        </div>
                    </div>
                    <br />
                </>}

                <div>
                    <div className='tyc_typer_cont'>
                        <div className='tyc_typer' id='type_cont'>
                        </div>
                    </div>
                </div>

                <div className='tyc_reload'>
                    <div>
                        <span className="material-symbols-outlined" onClick={againStart}>refresh</span>
                    </div>
                </div>

            </section>

        </>
    )
}
