import React from 'react'

export default function SmallDevice() {
    return (
        <>

            <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '80%', fontSize: '20px', color: 'white' }}>
                    TypeChamp is currently under development for mobiles.
                    Visit the site on a PC/Mac for the best experience.
                    Mobile version will be available soon!
                </div>
            </div>
        </>
    )
}
