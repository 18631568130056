import React from 'react'
import { Helmet } from "react-helmet";
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import '../component/css/notify.css'

export default function Chat(props) {
  return (
    <>
      <Navbar setProgress={props.setProgress} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeChamp | Chat</title>
        <link rel="canonical" href="https://www.typechamp.com/chat" />
      </Helmet>


      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <section>
        <div className='notify_box'>
          <div>
            <h3>Welcome to Typechamp.com</h3>
          </div>
          <div>
            We welcome you to our platform to make your typing journey smother and faster, Enjoy our game and typing test take as a part of your life.
          </div>
          <div className='notify_time'><span>1hr</span></div>
        </div>

        <div className='notify_box'>
          <div>
            <h3>Typechamp | Type</h3>
          </div>
          <div>
          Type your test respectively and get respective results are you able to share that results if you are logged in? Otherwise, you can take a screenshot.
          </div>
          <div className='notify_time'><span>1hr</span></div>
        </div>

        <div className='notify_box'>
          <div>
            <h3>Typechamp | competition</h3>
          </div>
          <div>
          Type your test respectively and get respective results and if your result is very good on our site this will rank on the leaderboard page try your best score.
          </div>
          <div className='notify_time'><span>1hr</span></div>
        </div>
      </section>

      <br />
      <br />
      <br />



      <Footer />
    </>
  )
}
