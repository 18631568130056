import { createSlice } from "@reduxjs/toolkit";

const nowTypeSlice = createSlice({
    name: "nowType",
    initialState:{
        correct:0,
        worng:0,
        second:30,
        typerun:false,
    },
    reducers:{
        addCorrect: (state,actions) =>{
            state.correct = actions.payload;
        },
        addWorng: (state,actions)=>{
            state.worng = actions.payload;
        },
        addSec: (state,actions) =>{
            state.second = actions.payload;
            console.log(actions.payload);
        },
        nowTrueTypeing: (state,actions) =>{
            state.typerun = actions.payload;
        },
    },
});

export const { addCorrect, addWorng, addSec, nowTrueTypeing } = nowTypeSlice.actions;

export default nowTypeSlice.reducer;