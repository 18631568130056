import html2canvas from 'html2canvas';

export const CaptureScreen =()=>{

    const body = document.body;
    const html = document.documentElement;
    const windowHeight = window.innerHeight;

    const height = Math.min(
      body.scrollHeight, body.offsetHeight, 
      html.clientHeight, html.scrollHeight, html.offsetHeight
    );

    html2canvas(document.documentElement, {
      scrollY: window.scrollY,
      windowHeight: windowHeight,
      height: windowHeight,
      windowWidth: window.innerWidth
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      
      const link = document.createElement('a');
      link.download = 'TypeCham_report.png';
      link.href = imgData;
      link.click();
    });

}