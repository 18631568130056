import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nowTrueTypeing } from '../store/nowTypeSlice';
import { useNavigate } from 'react-router-dom';


function ReverseCountdown({ seconds }) {
  const [count, setCount] = useState(seconds);
  const initialCountRef = useRef(seconds);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const host = "http://localhost:5000";
  const host = process.env.REACT_APP_API_REQUEST;

  const currect = useSelector((store) => store.nowType.correct);
  const worng = useSelector((store) => store.nowType.worng);
  const userids = useSelector((store) => store.user.userId);
  // const second = useSelector((store) => store.nowType.second);

  useEffect(() => {
    initialCountRef.current = seconds;
  }, [seconds]);


  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval);
          dispatch(nowTrueTypeing(true))
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return count
}

export default ReverseCountdown;