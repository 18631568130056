import './App.css';
import { useState } from 'react';
import About from './page/About';
import Competition from './page/Competition';
import Login from './page/Login';
import Navbar from './component/Navbar';
import Home from './page/Home';
import { Routes, Route } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import Type from './page/Type';
import Setting from './page/Setting';
import Game from './page/Game';
import Chat from './page/Chat';
import Signup from './page/Signup';
import Account from './page/Account';
import TypeResult from './component/TypeResult';
import RandomType from './page/RandomType';
import GameOne from './page/GameOne';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Feedback from './page/Feedback';
import SmallDevice from './component/SmallDevice';
import Otp from './page/Otp';
import Notify from './component/Notify';
import { SpeedInsights } from "@vercel/speed-insights/react"





function App() {

  const [progress, setProgress] = useState(0);
 
  


  const increasePro = (progress) => {
    setProgress(progress);
  }

  return (
    <>
      <SpeedInsights/>

      {window.innerWidth < 1200 ? <SmallDevice/>  
      
      
      
      
      :<>





      <LoadingBar color='white' height={3} progress={progress} onLoaderFinished={() => setProgress(0)} />
      <Notify />
      <Routes>

        <Route path='/' element={<>
          <Home setProgress={increasePro}/>
        </>} />

        <Route path='/type' element={<>
          <Type setProgress={increasePro}/>
        </>} />

        <Route path='/typer' element={<>
          <RandomType setProgress={increasePro}/>
        </>} />
        
        <Route path='/typespeed/:typeid' element={<>
          <TypeResult setProgress={increasePro}/>
        </>} />

        <Route path='/game' element={<>
          <Game setProgress={increasePro}/>
        </>} />

        <Route path='/about' element={<>
          <About setProgress={increasePro}/>
        </>} />

        <Route path='/competition' element={<>
          <Competition setProgress={increasePro}/>
        </>} />

        <Route path='/setting' element={<>
          <Setting setProgress={increasePro} />
        </>} />
        
        <Route path='/chat' element={<>
          <Chat  setProgress={increasePro} />
        </>} />

        <Route path='/login' element={<>
          <Login  setProgress={increasePro} />
        </>} />

        <Route path='/signup' element={<>
          <Signup setProgress={increasePro} />
        </>} />

        <Route path='/otp' element={<>
          <Otp setProgress={increasePro} />
        </>} />

        <Route path='/account' element={<>
          <Account setProgress={increasePro} />
        </>} />

        <Route path='/gameone' element={<>
          <GameOne setProgress={increasePro} />
        </>} />
        <Route path='/feedback' element={<>
          <Feedback setProgress={increasePro} />
        </>} />

      </Routes>

      
      </>}

    </>
  );
}

export default App;
