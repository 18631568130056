import React, { useState } from 'react'
import Navbar from '../component/Navbar'
import Footer from '../component/Footer'
import { useNavigate } from 'react-router-dom';

export default function Feedback(props) {

    const [credential, setCredential] = useState({ name: '', email: '',massage:'' });
    // const host = "http://localhost:5000"
    const host = process.env.REACT_APP_API_REQUEST;
    const navigate = useNavigate();

    const onChange = (e) => {
        setCredential({ ...credential, [e.target.name]: e.target.value })
    }


    const submit = async (e)=>{
        e.preventDefault();

        if (credential.email === '') {
         
            return document.getElementById('viewalert').innerText = "Email can not be blank.";
        }

        if (credential.name === '') {
        
            return document.getElementById('viewalert').innerText = "Password can not be blank.";
        }
        if (credential.massage === '') {
        
            return document.getElementById('viewalert').innerText = "Password can not be blank.";
        }




        const uresponse = await fetch(`${host}/feed/createrecord`, {
       
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: credential.email, name: credential.name,massage: credential.massage })
        });
        const json = await uresponse.json();
      
        if (json) {
         
        
            navigate('/');
           
        }
    }


    return (
        <>
            <Navbar setProgress={props.setProgress} />

            <br />
            <br />
            <br />
            <br />
            <br />

            <section style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '300px' }}>


                    <div>
                        <div className='inputLogin'>
                            <input id="name" value={credential.name} type='text' onChange={onChange} name="name" placeholder='Name' />
                        </div>
                        <div className='inputLogin'>
                            <input id="email" value={credential.email} type='email' onChange={onChange} name="email" placeholder='Email Address' />
                        </div>
                        <div className='inputLogin'>
                            <textarea id="massage" value={credential.massage} type='email' onChange={onChange} name="massage" placeholder='Message' />
                        </div>
                        <p id='viewalert' style={{ color: 'red' }}></p>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button className='btnLogin' onClick={submit} >Continue</button>
                        </div>


                    </div>

                </div>



            </section>

            <br />
            <br />
            <br />
            <br />
            <br />

            <Footer />
        </>
    )
}
