import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState:{
       userCreate: false,
       userId:'',
       name:'',
       notify:false,
       type:'error',
       massage:'nothing.'
       
    },
    reducers:{
        setUser: (state,actions) =>{
            state.userCreate = actions.payload;
        },
        setUserId: (state,actions) =>{
            state.userId = actions.payload;
        },
        setUsername: (state,actions) =>{
            state.name = actions.payload;
        },
        setNotify: (state,actions) =>{
            state.notify = actions.payload;
        },
        setNotifyType: (state,actions) =>{
            state.type = actions.payload;
        },
        setNotifyMassage: (state,actions) =>{
            state.massage = actions.payload;
        },
       
    },
});

export const { setUser ,setUserId,setUsername,setNotify,setNotifyType,setNotifyMassage} = userSlice.actions;

export default userSlice.reducer;