import React from 'react'
import { Helmet } from "react-helmet";
import '../component/css/competition.css'
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import { useState } from 'react';
import { useEffect } from 'react';

export default function Competition(props) {

    // const host = "http://localhost:5000";
    const host = process.env.REACT_APP_API_REQUEST;

  const [data, setData] = useState([])

  const fetchData = async () => {
    const uresponse = await fetch(`${host}/record/toprecord`, {
      method: 'GET',
  
    });
    const data = await uresponse.json();
    console.log(data);
    if (data) {
      setData(data);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])


  const getName =  (id)=>{

    let name = "aman"
    return id;
  }





  return (
    <>
      <Navbar setProgress={props.setProgress} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeChamp | Competition</title>
        <link rel="canonical" href="https://www.typechamp.com/competition" />
      </Helmet>

      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>

      <div className='leader_board_head' >
        <h2 >Leaderboard</h2>
        <div></div>
      </div>


      <section style={{display:'flex',justifyContent:'center'}}>
        <table className='table_list_set'>
          <tr>
            <th className='table_list_th'>Rank</th>
            <th className='table_list_th'>Name</th>
            <th className='table_list_th'>WPA</th>
            <th className='table_list_th'>Time</th>
            <th className='table_list_th'>Accuracy</th>
          </tr>

          {data.map((element, index) => {
            return <tr>
              <td><span style={{color:'aqua'}}>#</span>{index + 1}</td>
              <td>{element.name? element.name: '-'}</td>
              <td>{element.wpm}</td>
              <td>{element.time}s</td>
              <td>{element.accuracy}</td>
            </tr>
          })}

          
        </table>
      </section>







      <Footer />
    </>
  )
}
