import React from 'react'
import Navbar from '../component/Navbar'
import { Helmet } from 'react-helmet'
import Footer from '../component/Footer'

export default function GameOne(props) {
    return (
        <>
            <Navbar setProgress={props.setProgress} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>TypeChamp | Game</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>

            <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <div style={{textAlign:'center',color:'gray'}}>This Page in Under development</div>
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>


            <Footer />
        </>
    )
}
