import React from 'react'
import { Helmet } from "react-helmet";
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';

export default function About(props) {
  return (
    <>
      <Navbar setProgress={props.setProgress} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeChamp | About</title>
        <link rel="canonical" href="https://www.typechamp.com/about" />
      </Helmet>

      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <section style={{ display: 'flex', justifyContent: "center" }}>


          <div style={{ width: '1000px' }}>

            <div>
              <h2>Welcome to TypeChamp.com</h2>
            </div>

            <div>
              <br />
              <h3>1. Typing Tests</h3>
              <p style={{color:'gray'}}>Provide timed typing tests to assess users' current typing speed and accuracy. Offer a variety of texts to type, ranging from easy to difficult, to cater to different skill levels.</p>
            </div>
            <div>
              <br />
              <h3>2. Lessons and Tips</h3>
              <p style={{color:'gray'}}>Offer lessons and tips on how to improve typing speed and accuracy. Include proper finger placement, posture, and exercises to strengthen typing skills.</p>
            </div>
            <div>
              <br />
              <h3>3. Interactive Typing Games</h3>
              <p style={{color:'gray'}}>Create fun and engaging typing games that help users practice their typing skills in an enjoyable way. Include games that focus on speed, accuracy, and typing different types of text (e.g., numbers, symbols, letters).</p>
            </div>
            <div>
              <br />
              <h3>4. Progress Tracking</h3>
              <p style={{color:'gray'}}>Allow users to track their typing speed improvement over time. Provide charts or graphs that visually represent their progress, motivating them to continue practicing.</p>
            </div>

            <div>
              <br />
              <h3>5. yping Challenges</h3>
              <p style={{color:'gray'}}>Organize typing challenges or competitions where users can compete against each other to improve their typing speed and win prizes or recognition.</p>
            </div>
            <div>
              <br />
              <h3>6. Community and Forums</h3>
              <p style={{color:'gray'}}>Create a community where users can interact, share tips, and motivate each other to improve their typing skills.</p>
            </div>
            <div>
              <br />
              <h3>7. Typing Certificates</h3>
              <p style={{color:'gray'}}>Offer certificates or badges to users who achieve certain typing speed milestones, providing them with a sense of accomplishment and motivation to continue practicing.</p>
            </div>
            <div>
              <br />
              <h3>8. Mobile Compatibility</h3>
              <p style={{color:'gray'}}>Ensure your website is mobile-friendly, allowing users to practice typing on their smartphones or tablets whenever and wherever they are.</p>
            </div>
            <div>
              <br />
              <h3>9. Mission Statement</h3>
              <p style={{color:'gray'}}>Clearly state the mission of your website, which could be something like "Our mission is to help users improve their typing speed and accuracy through engaging and effective tools and resources."</p>
            </div>
            <div>
              <br />
              <h3>10. Founder's Story</h3>
              <p style={{color:'gray'}}>Share the story of how and why you started the website. This personal touch can help users connect with your mission on a deeper level.</p>
            </div>
            <div>
              <br />
              <h3>11. Unique Selling Proposition (USP)</h3>
              <p style={{color:'gray'}}>Highlight what sets your website apart from other typing speed improvement resources. This could include your unique approach, the range of tools and resources you offer, or the success stories of users who have benefited from your website.</p>
            </div>
            <div>
              <br />
              <h3>12. Team Members</h3>
              <p style={{color:'gray'}}> Introduce key members of your team, including their backgrounds and expertise in typing speed improvement or related fields.</p>
            </div>
            <div>
              <br />
              <h3>13. Partnerships and Collaborations</h3>
              <p style={{color:'gray'}}>Mention any partnerships or collaborations you have with other organizations or experts in the field of typing speed improvement.</p>
            </div>
            <div>
              <br />
              <h3>14. Testimonials</h3>
              <p style={{color:'gray'}}> Include testimonials from users who have benefited from your website. This social proof can help build trust with new visitors.</p>
            </div>
            <div>
              <br />
              <h3>15. Future Plans:</h3>
              <p style={{color:'gray'}}>Share your plans for the future of the website, such as new features or content you plan to add to further enhance the user experience.</p>
            </div>
            <div>
              <br />
              <h3>15. Contact Information</h3>
              <p style={{color:'gray'}}>Provide contact information for users to reach out with any questions, feedback, or collaboration opportunities.</p>
            </div>
          



          </div>
        </section>
        <br />
        <br />
        <br />
        <br />
      </div>
      <Footer />

    </>
  )
}
