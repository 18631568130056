import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { useEffect } from 'react';
import { Chart } from "react-google-charts";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { nowTrueTypeing } from '../store/nowTypeSlice';
import { CaptureScreen } from '../utils/CaptureScreen';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import {setNotify,setNotifyMassage} from '../store/userSlice';


export default function TypeResult(props) {

    let { typeid } = useParams();
    let date = new Date();
    // const host = "http://localhost:5000";
    const host = process.env.REACT_APP_API_REQUEST;
    const [currect, setcurrect] = useState(0)
    const [worng, setworng] = useState(0)
    const [second, setsecond] = useState(0)
    const [name, setName] = useState()

    const navigate = useNavigate();
    const dispatch = useDispatch();
    dispatch(nowTrueTypeing(false))





    const screenShort = () => {
        CaptureScreen();
    }

    const refreshData = () => {
        navigate('/type');
        props.setProgress(100);
    }


    const getRecord = async () => {

        const uresponse = await fetch(`${host}/record/recordid`, {
            method: 'GET',
            headers: {
                'id': typeid
            }
        });
        const data = await uresponse.json();

        if (!data.error) {
            console.log(data);
            setcurrect(data.wordC)
            setworng(data.wordW)
            setsecond(data.time)
            setName(data.name)
            
        }else{
            console.log('not error....');
        }
    }

    const data = [
        ["Task", "Hours per Day"],
        ["accurate", parseInt(currect / (currect + worng) * 100)],
        ["Eat", 100 - parseInt(currect / (currect + worng) * 100)],
    ];

    const data2 = [
        ["Data", "Currect/Wrong"],
        ["currect", currect],
        ["worng", worng],
    ];
    const data3 = [
        ["Task", "Hours per Day"],
        ["second", 120 - second],
        ["Total", second],
    ];

    const options = {
        title: "My Daily Activities",
        // is3D: true,
        backgroundColor: 'transparent',
        pieSliceText: 'value',
        chartArea: {
            width: '100%',
            height: '100%'
        },
        pieHole: 0.4,
        is3D: false,
        legend: {
            color: 'red',
        },
        legend: 'none',
        slices: {
            0: { color: '2d4142' },
            1: { color: 'transparent' }
        }
    };

    useEffect(() => {
        getRecord();
    }, [])


    const copyToClipboard = () => {
       
        var urltarge = `https://www.typechamp.com/typespeed/${typeid}`;
        navigator.clipboard.writeText(urltarge);
        dispatch(setNotifyMassage('Copy SuccessFully!'))
        dispatch(setNotify(true));
        
      }
   

    return (
        <>
            <Navbar setProgress={props.setProgress} />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <section id='result_type' className='' >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='resutl_firt_cont'>
                        <div>
                            <div className='tyc_res_sec'>
                                <div className='tyc_result'>
                                    <span>WPM</span>
                                    {second === 15 ? <span>{parseInt((currect / 5) / 0.25)}</span> : ''}
                                    {second === 30 ? <span>{parseInt((currect / 5) / 0.50)}</span> : ''}
                                    {second === 60 ? <span>{parseInt((currect / 5) / 1)}</span> : ''}
                                    {second === 120 ? <span>{parseInt((currect / 5) / 2)}</span> : ''}
                                    {/* <span>{parseInt((currect / 5) / 0.25)}</span> */}
                                </div>
                                <div className='tyc_result_more'>
                                    <span>Accuracy</span>
                                    <span>{parseInt(currect / (currect + worng) * 100)}%</span>
                                </div>
                                <div className='tyc_result_more'>
                                    <span>Time</span>
                                    <span>{second}s</span>
                                </div>
                                <div className='tyc_result_more'>
                                    <span>Word(c)</span>
                                    <span>{currect}</span>
                                </div>

                                <div className='tyc_result_more'>
                                    <span>Word(w)</span>
                                    <span>{worng}</span>
                                </div>
                                <div className='tyc_result_more'>
                                    <span>Word(t)</span>
                                    <span>{currect + worng}</span>
                                </div>
                                <div className='tyc_result_more'>
                                    <span>Setup</span>
                                    <span>{second === 30?'Default':'Custom'}</span>
                                </div>
                                <div className='tyc_result_more'>
                                    <span>Lanugage</span>
                                    <span>English</span>
                                </div>
                                <div className='tyc_result_more'>
                                    <span>Date</span>
                                    <span>{date.getDate() + "/" + (1 + date.getMonth()) + "/" + date.getFullYear()}</span>
                                </div>
                            </div>

                        </div>


                        <div style={{ display: 'flex', marginTop: '23px' }}>
                            <div>
                                <button className='btn_result' onClick={refreshData} ><span className="material-symbols-outlined">refresh</span></button>
                            </div>
                            <div>
                                <button className='btn_result' onClick={screenShort}><span className="material-symbols-outlined">screenshot_monitor</span></button>
                            </div>
                        </div>
                    </div>
                    <div className='result_second_cont'>


                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                            <div>
                                <Chart
                                    chartType="PieChart"
                                    data={data}
                                    options={options}
                                    width={"200px"}
                                    height={"200px"}
                                />
                                <div style={{ textAlign: 'center', marginTop: '25px' }}>
                                    <div>Accuracy</div>
                                    <div>{parseInt(currect / (currect + worng) * 100)}/100</div>
                                </div>
                            </div>

                            <div>
                                <Chart
                                    chartType="PieChart"
                                    data={data2}
                                    options={options}
                                    width={"200px"}
                                    height={"200px"}
                                />
                                <div style={{ textAlign: 'center', marginTop: '25px' }}>
                                    <div>Word(W) / Word(C)</div>
                                    <div>{worng}/{currect}</div>
                                </div>
                            </div>


                            <div>
                                <Chart
                                    chartType="PieChart"
                                    data={data3}
                                    options={options}
                                    width={"200px"}
                                    height={"200px"}
                                />
                                <div style={{ textAlign: 'center', marginTop: '25px' }}>
                                    <div>Time</div>
                                    <div>{second}/120</div>
                                </div>
                            </div>

                        </div>
                        <div style={{ marginTop: '25px', padding: '28px', borderTop: '1px solid',display:'flex',justifyContent:'space-between',alignItems:'center' }}>
                            <div>
                                Hey {name} if you copy this link you are to able send record.
                            </div>
                            <div>
                            <button className='btn_result' onClick={copyToClipboard} ><span class="material-symbols-outlined">content_copy</span></button>
                            
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <br />
            <br />

            <Footer />

        </>
    )
}
