import { configureStore } from "@reduxjs/toolkit";
import nowTypeSlice from "./nowTypeSlice";
import userSlice from "./userSlice";
// import cartSlice from "./cartSlice";

const appStore = configureStore({
    reducer: {
        nowType: nowTypeSlice,
        user: userSlice
        // cart: userSlice,
    }
});

export default appStore;