import React from 'react'
import '../component/css/login.css'
import { Helmet } from "react-helmet";
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useDispatch } from 'react-redux';
import {setNotify,setNotifyMassage} from '../store/userSlice';



export default function Login(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [credential, setCredential] = useState({  password: '', email:'' });
    // const host = "http://localhost:5000";
    const host = process.env.REACT_APP_API_REQUEST;
    // const clientId = process.env.GOOGLE_CLIENT_ID;
    const clientId = "768974449019-c0slgah7in7rhlr13cs295t9esodjb3b.apps.googleusercontent.com";
    // const clientId = "768974449019-0hiqj1qttng4sh9a55omeai8mrjqg7bh.apps.googleusercontent.com";
    // const clientId = "324523529824-ebp87lc87hogqkjmfqua61547ca0ha2s.apps.googleusercontent.com";
    console.log(clientId);


    const clickpro = () => {
        props.setProgress(100);
    }

    const onSignupSuccess = async (res) => {
        console.log(res.email);
        console.log(res.name);

        const response = await fetch(`${host}/auth/googleauth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: res.name, email: res.email })
        });
        const json = await response.json();

        if (json) {
            localStorage.setItem('token', json.authToken)
            navigate('/');
            dispatch(setNotifyMassage('Login SuccessFully!'))
            dispatch(setNotify(true));
        
        }
    }

    const onSignupFailure = () => {
        console.log('Some error are occuring please try again.');
    }


    const submit = async (e)=>{
        e.preventDefault();

        if (credential.email === '') {
         
            return document.getElementById('viewalert').innerText = "Email can not be blank.";
        }

        if (credential.password === '') {
        
            return document.getElementById('viewalert').innerText = "Password can not be blank.";
        }




        const uresponse = await fetch(`${host}/auth/getemail`, {
            method: 'GET',
            headers: {
                'email': credential.email
            }
        });
        const nameExited = await uresponse.json();
        if (nameExited.success) {
          
            return document.getElementById('viewalert').innerText = "Email Not Found.";
        }

 
        const response = await fetch(`${host}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: credential.email, password: credential.password })
        });
        const json = await response.json();
      
        if (json) {
            if (json.errors) {
              
                document.getElementById('viewalert').innerText = "innCorrect password.";
            } else {
                dispatch(setNotifyMassage('Login SuccessFully!'))
                dispatch(setNotify(true));

                localStorage.setItem('token', json.authToken)
                navigate('/');
            }
        }
    }

        
  const onChange = (e) => {
    setCredential({ ...credential, [e.target.name]: e.target.value })
  }

    return (
        <>
           
            <Navbar setProgress={props.setProgress} />

            <Helmet>
                <meta charSet="utf-8" />
                <title>TypeChamp | Login</title>
                <link rel="canonical" href="https://www.typechamp.com/login" />
            </Helmet>

            <br />
            <br />
            <br />
            <br />
            <br />


            <section style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '300px' }}>
                    <div style={{ padding: '0px 10px' }}>
                        <h2>Welcome back</h2>
                    </div>
                    <div>
                        <div className='inputLogin'>
                            <input id="email" value={credential.email} type='email' onChange={onChange} name="email" placeholder='Email Address' />
                        </div>
                        <div className='inputLogin'>
                            <input  id="password" value={credential.password} type='password' onChange={onChange} name="password"  placeholder='Password' />
                        </div>
                        <p id='viewalert' style={{ color: 'red' }}></p>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button className='btnLogin' onClick={submit}>Continue</button>
                        </div>
                        <div style={{ padding: '0px 10px' }}>
                            <p>Don't have a account? <Link to="/signup" onClick={clickpro} className='atagLog'>Signup</Link></p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <hr style={{ width: '100px' }} />
                            <p style={{ margin: '10px' }}>OR</p>
                            <hr style={{ width: '100px' }} />
                        </div>

                        <div className='google_btn_w'>
                            <GoogleOAuthProvider clientId={clientId}>
                                
                            
                                                        {/* <GoogleLogin
                                onSuccess={credentialResponse => {
                                    console.log(credentialResponse);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                                />  */}
                                <GoogleLogin  buttonText="Signup With Google"  onSuccess={credentialResponse => {
                                    const decoded = jwtDecode(credentialResponse.credential);
                                    onSignupSuccess(decoded)
                                    console.log(decoded);
                                }} onError={onSignupFailure} />
                            </GoogleOAuthProvider>
                        </div>
                    </div>
                </div>
            </section>

            <br />
            <br />
            <br />
            <br />
            <br />


            <Footer />


        </>
    )
}
