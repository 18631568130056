import React from 'react'
import Navbar from '../component/Navbar'
import Footer from '../component/Footer'
import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setUserId ,setNotify,setNotifyMassage} from '../store/userSlice';
import '../component/css/account.css'



export default function Account(props) {

  const dispatch = useDispatch();
  const host = process.env.REACT_APP_API_REQUEST;
  // const host = "http://localhost:5000";
  const [data, setData] = useState([])
  const navigate = useNavigate();
  const userSetId = useSelector((store) => store.user.userId);
  const userName = useSelector((store) => store.user.name);
  console.log(userSetId);

  const fetchData = async () => {
    const uresponse = await fetch(`${host}/record/getrecord`, {
      method: 'GET',
      headers: {
        'id': userSetId
      }
    });
    const data = await uresponse.json();
    console.log(data);
    if (data) {
      setData(data);
    }
  }
  const logOut = () => {
    
    localStorage.clear();
    dispatch(setUser(false));
    navigate('/');
    dispatch(setNotifyMassage('LogOut SuccessFully!'))
    dispatch(setNotify(true));
  }
  
  useEffect(() => {
    fetchData();
  }, [])
  
  
  const notify = () => {
    console.log('work.');
   
    dispatch(setNotifyMassage('working properly..'))
    dispatch(setNotify(true));


  }




  return (
    <>
     
      <Navbar setProgress={props.setProgress} />
      
      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeChamp | Account</title>
        <link rel="canonical" href="https://www.typechamp.com/account" />
      </Helmet>



      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>

      <div className='acc-top-cont'>
        <div className='acc-cont1'>
          <div style={{ padding: "20px 20px 20px 0px" }}>
            <span>{userName}</span>
          </div>
          <div className='acc-count3'>

            <div>

              <div style={{ margin: "10px 10px 10px 0px" }}>
                <a style={{color:'aqua'}} href="">change username</a>
              </div>
              <div>
                <a style={{color:'aqua'}} href="">change password</a>
              </div>
            </div>
            <div><button className='acc-logout' style={{cursor:'pointer'}} onClick={logOut}>Logout</button></div>
            <div style={{display:'none'}}><button onClick={notify}>Notify</button></div>
          </div>
        </div>


        
   
        <div className='acc-cont2'>
          Hi {userName},
          <div style={{ marginTop: "20px", color:'gray' }}>
          We welcome you to our platform to make your typing journey smother and faster, Enjoy our game and typing test take as a part of your life. <br/><br/>
          Type your test respectively and get respective results are you able to share that results if you are logged in? Otherwise, you can take a screenshot.
          </div>
        </div>
      </div>


      <div>
        <br />
        <br />
        <br />
      </div>




      <div className='leader_board_head' >
          <h2 >Your History</h2>
          <div></div>
        </div>


      <section style={{ display: 'flex', justifyContent: 'center' }}>

      {data.length !== 0? <>  

        <table className='table_list_set'>
          <tr>
            <th className='table_list_th'>Rank</th>
            <th className='table_list_th'>Name</th>
            <th className='table_list_th'>WPA</th>
            <th className='table_list_th'>Time</th>
            <th className='table_list_th'>Accuracy</th>
          </tr>

          

            {data.map((element, index) => {
              return <tr>
                <td><span style={{color:'aqua'}}>#</span>{index + 1}</td>
                <td>{element.name? element.name : '-'}</td>
                <td>{element.wpm}</td>
                <td>{element.time}s</td>
                <td>{element.accuracy}</td>
              </tr>
            })}

         

        </table>

        </>:<>  <center> No Data To Show</center>    </>}


      </section>



      <Footer />

    </>
  )
}
