import React from 'react'
import Navbar from '../component/Navbar'
import Footer from '../component/Footer'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {setNotify,setNotifyMassage} from '../store/userSlice';

export default function Otp(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [credential, setCredential] = useState({ otp: '', email: '' });
    // const host = "http://localhost:5000";
    const host = process.env.REACT_APP_API_REQUEST;

    const onChange = (e) => {
        setCredential({ ...credential, [e.target.name]: e.target.value })
    }

    const submit = async (e)=>{
        e.preventDefault();

        if (credential.otp === '') {
            return document.getElementById('viewalert').innerText = "OTP can not be blank.";
        }
 
        const response = await fetch(`${host}/evotp/checkotp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: localStorage.getItem('email'), userotp: parseInt( credential.otp) })
        });
        const json = await response.json();
      
        if (json) {
            console.log(json);
            if (json.error) {
                
                console.log(credential.otp);
                console.log(localStorage.getItem('email'));
                document.getElementById('viewalert').innerText = "innCorrect OTP.";
            } else {
                dispatch(setNotifyMassage('Created SuccessFully!'))
                dispatch(setNotify(true));
            
             
                localStorage.setItem('token', json.authToken)
                navigate('/');
            }
        }
    }



    return (
        <>
            <Navbar setProgress={props.setProgress} />
            <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <section style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '300px' }}>
                        <div style={{ padding: '0px 10px' }}>
                            <h2>Enter OTP</h2>
                        </div>
                        <div>
                            <div className='inputLogin'>
                                <input id="otp" value={credential.otp} type='text' onChange={onChange} name="otp" placeholder='OTP' />
                            </div>

                            <p id='viewalert' style={{ color: 'red' }}></p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button className='btnLogin' onClick={submit} >Continue</button>
                            </div>

                        </div>
                    </div>
                </section>
                <br />
                <br />
                <br />
                <br />
            </div>
            <Footer />
        </>
    )
}
