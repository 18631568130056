import React, { useState } from 'react'
import { Helmet } from "react-helmet";
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useDispatch } from 'react-redux';
import {setNotify,setNotifyMassage} from '../store/userSlice';

// import { GoogleLogin } from '@react-oauth/google';

export default function Signup(props) {
    const dispatch = useDispatch();
    const [credential, setCredential] = useState({ name: '', password: '', email:'' });
    const navigate = useNavigate();
    // const host = "http://localhost:5000";
    const host = process.env.REACT_APP_API_REQUEST;
    // const clientId = process.env.GOOGLE_CLIENT_ID;
    const clientId = "768974449019-c0slgah7in7rhlr13cs295t9esodjb3b.apps.googleusercontent.com";
    // const clientId = "768974449019-0hiqj1qttng4sh9a55omeai8mrjqg7bh.apps.googleusercontent.com";

    const clickpro = () => {
        props.setProgress(100);
    }


    const onSignupSuccess = async (res)=>{
        console.log(res.email);
        console.log(res.name);

        const response = await fetch(`${host}/auth/googleauth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: res.name, email: res.email })
        });
        const json = await response.json();

        if (json) {
            localStorage.setItem('token', json.authToken)
            navigate('/');
            dispatch(setNotifyMassage('Created SuccessFully!'))
            dispatch(setNotify(true));
        }
    }

    const submit = async (e)=>{
        e.preventDefault();

        if (credential.name === '') {
            return document.getElementById('viewalert').innerText = "Username can not be blank.";
        }

        if (credential.email === '') {
            return document.getElementById('viewalert').innerText = "Email can not be blank.";
        }

        if (credential.password === '') {
            return document.getElementById('viewalert').innerText = "Password can not be blank.";
        }




        const uresponse = await fetch(`${host}/auth/getemail`, {
            method: 'GET',
            headers: {
                'email': credential.email
            }
        });
        const nameExited = await uresponse.json();
        if (!nameExited.success) {
            return document.getElementById('viewalert').innerText = "Email already exists.";
        }

 
        const response = await fetch(`${host}/evotp/adduser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ username: credential.name, email: credential.email, password: credential.password})
        });
        const json = await response.json();
     
        if (json) {
          setCredential({name:'',email:'',password:''})
          localStorage.setItem('email', credential.email)
          navigate('/otp');
          dispatch(setNotifyMassage('OTP Send SuccessFully!'))
          dispatch(setNotify(true));

        }



    }

    const onSignupFailure = (res)=>{
        console.log('Some error are occuring please try again.');
    }

    
  const onChange = (e) => {
    setCredential({ ...credential, [e.target.name]: e.target.value })
  }






    return (
        <>

            <Navbar setProgress={props.setProgress} />

            <Helmet>
                <meta charSet="utf-8" />
                <title>TypeChamp | Signup</title>
                <link rel="canonical" href="https://www.typechamp.com/signup" />
            </Helmet>

            <br />
            <br />
            <br />
            <br />
            <br />


            <section style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '300px' }}>
                    <div style={{ padding: '0px 10px' }}>
                        <h2>Create your account</h2>
                    </div>
                    <div>
                        <div className='inputLogin'>
                            <input id="name" value={credential.name} type='text' onChange={onChange} name="name"  placeholder='User Name' />
                        </div>
                        <div className='inputLogin'>
                            <input id="email" value={credential.email} type='text' onChange={onChange} name="email"  placeholder='Email Address' />
                        </div>
                        <div className='inputLogin'>
                            <input id="password" value={credential.password} type='password' onChange={onChange} name="password"  placeholder='Password' />
                        </div>
                        <p id='viewalert' style={{ color: 'red' }}></p>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button  className='btnLogin' onClick={submit}>Continue</button>
                        </div>
                        <div style={{ padding: '0px 10px' }}>
                            <p>Already have an account? <Link onClick={clickpro} to="/login" className='atagLog'>Login</Link></p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <hr style={{ width: '100px' }} />
                            <p style={{ margin: '10px' }}>OR</p>
                            <hr style={{ width: '100px' }} />
                        </div>
                        <div className='google_btn_w'>
                            
                        <GoogleOAuthProvider clientId={clientId}>
                                
                            
                      
                            <GoogleLogin  buttonText="Signup With Google"  onSuccess={credentialResponse => {
                                const decoded = jwtDecode(credentialResponse.credential);
                                onSignupSuccess(decoded)
                                console.log(decoded);
                            }} onError={onSignupFailure} />
                        </GoogleOAuthProvider>      
                        </div>
                     
                    </div>
                </div>
            </section>

            <br />
            <br />
            <br />
            <br />
            <br />


            <Footer />
        </>
    )
}
