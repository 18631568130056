import React from 'react'
import { Helmet } from "react-helmet";
import '../component/css/game.css'
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';

export default function Game(props) {
  return (
    <>
      <Navbar setProgress={props.setProgress} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeChamp | Game</title>
        <link rel="canonical" href="https://www.typechamp.com/game" />
      </Helmet>

      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

      </div>

      <section style={{ display: 'flex', justifyContent: 'center' }}>

        <div className='game_box'>
          <div >
            <Link to="/gameone">

            <img width={375} src="https://cdn1.typingtyping.com/wp-content/uploads/2019/11/z-type-game-min.png" alt="" />
            </Link>
          </div>
          <div>
            <h3>Z-Type</h3>
            <div className='notify_coming'>Coming soon</div>
            
            <div style={{ display: "flex", justifyContent: 'space-between' }}>


              <div className='tagGreen'>
                <span className="material-symbols-outlined">
                  play_circle
                </span>
                <div>
                  Play
                </div>
              </div>

              <div className='tagGold'>
                <span className="material-symbols-outlined">
                  leaderboard
                </span>
                <div>
                  Leaderboard
                </div>
              </div>


              <div className='tagBlue'>
                <span className="material-symbols-outlined">
                  info
                </span>
                <div>
                  About
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='game_box'>
          <div >
          <Link to="/gameone">
            <img width={375} style={{ height: '275px' }} src="https://www.typinggames.zone/web/game-thumbnails/typingattack-small.png" alt="" />
          </Link>
          </div>
          <div>
            <h3>Typing Attack</h3>
            <div className='notify_coming'>Coming soon</div>
            <div style={{ display: "flex", justifyContent: 'space-between' }}>


              <div className='tagGreen'>
                <span className="material-symbols-outlined">
                  play_circle
                </span>
                <div>
                  Play
                </div>
              </div>

              <div className='tagGold'>
                <span className="material-symbols-outlined">
                  leaderboard
                </span>
                <div>
                  Leaderboard
                </div>
              </div>


              <div className='tagBlue'>
                <span className="material-symbols-outlined">
                  info
                </span>
                <div>
                  About
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='game_box' >
          {/* <div className='game_box' style={{ filter: 'blur(2px)' }}> */}
          <div >
            <Link to="/gameone">

            <img width={375} height={275} src="https://www.kidztype.com/thumbs/space-typing-junior.webp" alt="" />
            </Link>
          </div>
          <div>
            <h3>Space Typing</h3>
            <div className='notify_coming'>Coming soon</div>
            <div style={{ display: "flex", justifyContent: 'space-between' }}>


              <div className='tagGreen'>
                <span className="material-symbols-outlined">
                  play_circle
                </span>
                <div>
                  Play
                </div>
              </div>

              <div className='tagGold'>
                <span className="material-symbols-outlined">
                  leaderboard
                </span>
                <div>
                  Leaderboard
                </div>
              </div>


              <div className='tagBlue'>
                <span className="material-symbols-outlined">
                  info
                </span>
                <div>
                  About
                </div>
              </div>
            </div>
          </div>
        </div>


      </section>

      {/* <section style={{ display: 'flex', justifyContent: 'center' }}>



      
        <div className='game_box'>
          <div >
            <img width={375} src="https://cdn1.typingtyping.com/wp-content/uploads/2019/11/z-type-game-min.png" alt="" />
          </div>
          <div>
            <h3>Target Words</h3>
            <div style={{ display: "flex", justifyContent: 'space-between' }}>


              <div className='tagGreen'>
                <span className="material-symbols-outlined">
                  play_circle
                </span>
                <div>
                  Play
                </div>
              </div>

              <div className='tagGold'>
                <span className="material-symbols-outlined">
                  leaderboard
                </span>
                <div>
                  Leaderboard
                </div>
              </div>


              <div className='tagBlue'>
                <span className="material-symbols-outlined">
                  info
                </span>
                <div>
                  About
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='game_box'>
          <div >
            <img width={375} src="https://cdn1.typingtyping.com/wp-content/uploads/2019/11/z-type-game-min.png" alt="" />
          </div>
          <div>
            <h3>Coming Soon...</h3>
            <div style={{ display: "flex", justifyContent: 'space-between' }}>


              <div className='tagGreen'>
                <span className="material-symbols-outlined">
                  play_circle
                </span>
                <div>
                  Play
                </div>
              </div>

              <div className='tagGold'>
                <span className="material-symbols-outlined">
                  leaderboard
                </span>
                <div>
                  Leaderboard
                </div>
              </div>


              <div className='tagBlue'>
                <span className="material-symbols-outlined">
                  info
                </span>
                <div>
                  About
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='game_box' style={{filter:'blur(2px)'}}>
          <div >
            <img width={375} src="https://cdn1.typingtyping.com/wp-content/uploads/2019/11/z-type-game-min.png" alt="" />
          </div>
          <div>
          <h3>Coming Soon...</h3>
            <div style={{ display: "flex", justifyContent: 'space-between' }}>


              <div className='tagGreen'>
                <span className="material-symbols-outlined">
                  play_circle
                </span>
                <div>
                  Play
                </div>
              </div>

              <div className='tagGold'>
                <span className="material-symbols-outlined">
                  leaderboard
                </span>
                <div>
                  Leaderboard
                </div>
              </div>


              <div className='tagBlue'>
                <span className="material-symbols-outlined">
                  info
                </span>
                <div>
                  About
                </div>
              </div>
            </div>
          </div>
        </div>

     
      </section> */}


      <br />
      <br />
      <br />
      <br />

      <Footer />
    </>
  )
}
