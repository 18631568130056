import React from 'react'
import './css/navbar.css'
import { Link, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setUserId ,setUsername} from '../store/userSlice';


export default function Navbar(props) {

  // const host = "http://localhost:5000";
  const host = process.env.REACT_APP_API_REQUEST;
  const location = useLocation();
  const dispatch = useDispatch();

  const userFix = useSelector((store) => store.user.userCreate);


  const clickpro = () => {
    props.setProgress(100);
  }

  const fetchData = async () => {
    if (localStorage.getItem('token')) {
      const uresponse = await fetch(`${host}/auth/getuser`, {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token')
        }
      });
      const userid = await uresponse.json();
      if (!userid.error) {
        dispatch(setUser(true));
        dispatch(setUserId(userid._id));
        dispatch(setUsername(userid.username));
      } else {
        dispatch(setUser(false));
        localStorage.clear();
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [])


  return (
    <>
      <section>
        <div className='header-inner'>
          <div className='logo'>

            <span className="material-symbols-outlined logo_keyboard">keyboard</span>
            <span style={{ marginLeft: '5px' }}>TypeChamp</span>
            <div className='headerstageTag'>BETA</div>
          </div>
          <div className='header_link'>

            <Link onClick={clickpro} to="/" style={{ textDecoration: 'none', color: '#0dffff' }}>
              <div className={`${location.pathname === '/' ? 'h_link_active' : 'h_link'}`}>
                <span className="material-symbols-outlined">home</span>
              </div>
            </Link>

            <Link onClick={clickpro} to="/type" style={{ textDecoration: 'none', color: '#0dffff' }}>
              <div className={`${location.pathname === '/type' ? 'h_link_active' : 'h_link'}`}>
                <span className="material-symbols-outlined">keyboard</span>
              </div>
            </Link>

            <Link onClick={clickpro} to="/game" style={{ textDecoration: 'none', color: '#0dffff' }}>
              <div className={`${location.pathname === '/game' ? 'h_link_active' : 'h_link'}`}>
                <span className="material-symbols-outlined">stadia_controller</span>
              </div>
            </Link>

            <Link onClick={clickpro} to="/competition" style={{ textDecoration: 'none', color: '#0dffff' }}>
              <div className={`${location.pathname === '/competition' ? 'h_link_active' : 'h_link'}`}>
                <span className="material-symbols-outlined">rewarded_ads</span>
              </div>
            </Link>

            <Link onClick={clickpro} to="/about" style={{ textDecoration: 'none', color: '#0dffff' }}>
              <div className={`${location.pathname === '/about' ? 'h_link_active' : 'h_link'}`}>
                <span className="material-symbols-outlined">info</span>
              </div>
            </Link>

            <Link onClick={clickpro} to="/setting" style={{ textDecoration: 'none', color: '#0dffff' }}>
              <div className={`${location.pathname === '/setting' ? 'h_link_active' : 'h_link'}`}>
                <span className="material-symbols-outlined">settings</span>
              </div>
            </Link>


            <Link onClick={clickpro} to="/chat" style={{ textDecoration: 'none', color: '#0dffff' }}>
              <div className={`${location.pathname === '/chat' ? 'h_link_active' : 'h_link'}`}>
                <span className="material-symbols-outlined">chat</span>
              </div>
            </Link>


            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>
              {userFix ?
                <Link onClick={clickpro} to="/account" style={{ textDecoration: 'none' }}>
                  <div className='btn_setup'>
                    <svg className='cls-1' width={105} id="eFZJgmCx5Ib1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 15" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                      <g transform="translate(-135-45)">
                        <rect width="40" height="15" rx="2" ry="2" className="cls-1" transform="translate(135 45)" fill="#00000017" strokeWidth="0" />
                      </g>
                    </svg>
                    <div className='btn_account'>
                      <span>Account</span>
                      {/* <span className="material-symbols-outlined">login</span> */}
                    </div>
                  </div>
                </Link> :

                <Link onClick={clickpro} to="/login" style={{ textDecoration: 'none' }}>
                  <div className='btn_setup'>
                    <svg className='cls-1' width={96} id="eFZJgmCx5Ib1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 15" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                      <g transform="translate(-135-45)">
                        <rect width="40" height="15" rx="2" ry="2" className="cls-1" transform="translate(135 45)" fill="#00000017" strokeWidth="0" />
                      </g>
                    </svg>
                    <div className='btn_login'>
                      <span>Login</span>
                      <span className="material-symbols-outlined">login</span>
                    </div>
                  </div>
                </Link>}
            </div>

          </div>
        </div>

      </section>



    </>
  )
}
