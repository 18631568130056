import React from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { setNotify } from '../store/userSlice';

export default function Notify() {
    const dispatch = useDispatch();

    const notify = useSelector((store) => store.user.notify);
    const type = useSelector((store) => store.user.type);
    const massage = useSelector((store) => store.user.massage);

    if (notify) {


        toast.success(massage, {
            position: "bottom-right",
            theme: "dark",
        });





        dispatch(setNotify(false));
    }




  return (
    <ToastContainer />
  )
}
